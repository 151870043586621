export enum InviteTokenError {
  InvalidToken = "invalid token",
  AlreadyJoined = "already part of channel",
}

export enum EmailFileStatus {
  NotStarted = "not_started",
  Pending = "pending",
  Processed = "processed",
  Failed = "failed",
  Cancelled = "cancelled",
}

export enum EmailFileUploadError {
  AlreadyProcessing = "file is currently being processing for this channel",
  ServerCapacity = "too many uploads",
  NoEmailsFound = "no valid emails found in the file",
  FileLimitExceeds = "expected size is less than",
  Timeout = "timeout",
}

export enum ChannelInviteTokenType {
  /** public invite token. Generic token that can be shared. Can be claimed unlimited times. */
  Public = "public",
  /** private invite token. Sent to a specific user, it can be claimed by any 1 email.*/
  Private = "private",
}

export interface UploadEmailFileResponse {
  emailFileId: string;
  channelId: number;
  parsedEmailCount: number;
}

export interface EmailFile {
  id: string;
  parsedEmailCount: number | null;
  status: EmailFileStatus;
}

export interface GetEmailFilesResponse {
  data: EmailFile[];
}

export interface ProcessEmailFileRequest {
  emailFileId: string;
  title: string;
  body: string;
}
